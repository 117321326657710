import { useWindowSize } from '@vueuse/core';
import { computed, ref, onMounted, onUnmounted } from 'vue';
/**
 * @deprecated Please use css when possible in order to prevent hydration missmatch.
 */
export const useIsMobile = () => {
  const { width } = useWindowSize();

  const isMounted = ref(false);

  onMounted(() => {
    isMounted.value = true;
  });

  onUnmounted(() => {
    isMounted.value = false;
  });

  const isMobile = computed(() =>
    isMounted.value ? width.value < 768 : false,
  );

  const isTablet = computed(() =>
    isMounted.value ? width.value >= 768 && width.value < 1024 : false,
  );

  const isDesktop = computed(() =>
    isMounted.value ? width.value >= 1024 : true,
  );

  const isMobileOrTablet = computed(() =>
    isMounted.value ? width.value < 1024 : false,
  );

  const result = computed(() => isMobile.value);

  Object.defineProperties(result, {
    isTablet: {
      get: () => isTablet.value,
    },
    isDesktop: {
      get: () => isDesktop.value,
    },
    isMobileOrTablet: {
      get: () => isMobileOrTablet.value,
    },
  });

  return result;
};
